import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { B2C } from 'cosmos-components';

import { useSciVideoContent } from 'src/hooks/useSciVideoContent';
import { VideoContent } from 'src/types/SciContent';
import globalMsg from 'src/messages';
import { useGoBack } from 'src/hooks/useGoBack';

import * as S from './videos.form.style';
import { MODULE_PREFIX } from './videos.constants';
import { sciVideoContentKeys } from 'src/constants';
import { useAcceptVideo } from './videos.hooks';
import msg from './videosMessages';

interface VideosFormProps {
    successCallback: () => void;
}

export const VideosForm = ({ successCallback }: VideosFormProps) => {
    const intl = useIntl();
    const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
    const { mutate, invalidateSciData } = useAcceptVideo();
    const { goBack } = useGoBack();

    const { data: sciVideoLinks, isLoading: isSciVideoLinksLoading } =
        useSciVideoContent(sciVideoContentKeys.sciVideosVideo);

    const currentVideo: VideoContent | undefined =
        sciVideoLinks?.[currentVideoIndex];

    const onSubmit = useCallback(
        (e: React.FormEvent): void => {
            e.preventDefault();

            const isLastVideo = () =>
                sciVideoLinks && currentVideoIndex === sciVideoLinks.length - 1;

            if (currentVideo) {
                mutate(currentVideo);
                invalidateSciData();
            }

            if (!!sciVideoLinks && !isLastVideo()) {
                setCurrentVideoIndex(currentVideoIndex + 1);
            } else {
                successCallback();
            }
        },
        [
            currentVideo,
            currentVideoIndex,
            mutate,
            invalidateSciData,
            sciVideoLinks,
            successCallback,
        ],
    );

    const handleBackClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>): void => {
            if (currentVideoIndex > 0) {
                setCurrentVideoIndex(currentVideoIndex - 1);
                invalidateSciData();
            } else {
                goBack();
            }
        },
        [currentVideoIndex, goBack, invalidateSciData],
    );

    return (
        <S.StyledForm onSubmit={onSubmit}>
            <B2C.LoaderContainer loading={isSciVideoLinksLoading} size="medium">
                {currentVideo && (
                    <div>
                        <S.VideosFormHeader>
                            <h3>{currentVideo.title}</h3>
                            <h3>
                                {intl.formatMessage(msg.pageItems.numbering, {
                                    current: currentVideo.order,
                                    total: sciVideoLinks?.length,
                                })}
                            </h3>
                        </S.VideosFormHeader>
                        <S.VideoWrapper>
                            <S.ResponsiveIframe
                                src={currentVideo.url}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="video player"
                                data-test-id={`${MODULE_PREFIX}-video-player`}
                            />
                        </S.VideoWrapper>
                    </div>
                )}
            </B2C.LoaderContainer>
            <S.ActionWrapper>
                <S.StyledSecondaryButton
                    data-test-id={`${MODULE_PREFIX}-back`}
                    type="button"
                    onClick={handleBackClick}
                >
                    {intl.formatMessage(globalMsg.buttons.back)}
                </S.StyledSecondaryButton>
                <S.StyledPrimaryButton
                    data-test-id={`${MODULE_PREFIX}-save`}
                    type="submit"
                >
                    {intl.formatMessage(msg.buttons.continue)}
                </S.StyledPrimaryButton>
            </S.ActionWrapper>
        </S.StyledForm>
    );
};
