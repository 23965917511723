import { Currencies } from 'src/types/Currencies';

export enum AvailabilityType {
    pickUp = 'pickUp',
    dropOff = 'dropOff',
}

export interface TimeSlotsId {
    organizationId: string;
    timeSlotId: string;
}

export interface TimeSlot {
    _id: TimeSlotsId;
    startTime: string;
    endTime: string;
    isAvailable: boolean;
    isFull: boolean;
    price: {
        value: number;
        currency: Currencies;
    } | null;
}

export interface GetAssociatedTimeSlotsAvailabilitiesResponseType {
    pickUpTimeSlot: TimeSlot | null;
    dropOffTimeSlot: TimeSlot | null;
}

export interface PostAssociatedTimeSlotsAvailabilitiesPayload {
    timeSlot: TimeSlot;
    type: AvailabilityType;
}
