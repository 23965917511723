import { Countries } from 'src/modules/updateCustomerInfo/types';

export const convertObjectToArrayByKey = (
    object: any,
    itemKey: string,
): any[] => {
    if (!object) {
        return [];
    }
    return Object.keys(object).map((key) => {
        const item = object[key];
        return item[itemKey];
    });
};

export const sleep = async (msTime: number) =>
    new Promise((resolve) => setTimeout(resolve, msTime));

export const getSortedCountryNames = (
    points: Countries,
    favouriteCountryCodes: string[],
): string[] => {
    const countries = Object.entries(points).map(([index, country]) => ({
        code: country.code,
        name: country.name,
    }));
    const sortedCountryNames = countries
        .map((country) => country.name)
        .sort((c1, c2) => {
            return c1 < c2 ? -1 : 1;
        });

    const countriesSortedByName = countries.sort((c1, c2) => {
        return c1.name < c2.name ? -1 : 1;
    });

    const sortedCountries = countriesSortedByName.sort((c1, c2) => {
        const indexC1 =
            favouriteCountryCodes.indexOf(c1.code) >= 0
                ? favouriteCountryCodes.indexOf(c1.code)
                : sortedCountryNames.indexOf(c1.name) +
                  favouriteCountryCodes.length;
        const indexC2 =
            favouriteCountryCodes.indexOf(c2.code) >= 0
                ? favouriteCountryCodes.indexOf(c2.code)
                : sortedCountryNames.indexOf(c2.name) +
                  favouriteCountryCodes.length;

        return indexC1 - indexC2;
    });

    return sortedCountries.map((country) => country.name);
};
