import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { B2C, ReactHookSelect } from 'cosmos-components';

import globalMsg from 'src/messages';
import { useGoBack } from 'src/hooks/useGoBack';
import { NotificationBannerMessage } from 'src/components/shared/NotificationBannerMessage';
import { sciTheme } from 'src/themes';

import { useTimeSlotsAssociations } from './hooks/timeslots.hooks';
import { useTimeSlotsForm } from './hooks/timeslots.form.hooks';

import * as SForm from './timeslots.form.style';
import msg from './timeslots.messages';
import { MODULE_PREFIX } from './timeslots.constants';
import { createLabel } from './timeslots.utils';

interface TimeSlotsFormProps {
    successCallback: () => void;
}

export const TimeSlotsForm = ({ successCallback }: TimeSlotsFormProps) => {
    const intl = useIntl();
    const { goBack } = useGoBack();

    const { pickUpAvailabilities, dropOffAvailabilities, isLoading } =
        useTimeSlotsAssociations();
    const {
        control,
        showPrice,
        isError,
        isLoading: isFormLoading,
        onSubmit,
    } = useTimeSlotsForm(successCallback);

    if (
        !pickUpAvailabilities.some((p) => p.isAvailable) &&
        !dropOffAvailabilities.some((p) => p.isAvailable) &&
        !isLoading
    ) {
        successCallback();
    }

    if (isLoading) {
        return (
            <SForm.StyledForm>
                <B2C.LoaderSpinner />
            </SForm.StyledForm>
        );
    }

    return (
        <SForm.StyledForm onSubmit={onSubmit}>
            <B2C.LoaderContainer
                loading={isFormLoading}
                opacity={{ opacity: '90%', color: sciTheme.colors.b2cWhite }}
            >
                <SForm.HeaderContainer>
                    <SForm.Header>
                        {intl.formatMessage(msg.pageItems.formTitle)}
                    </SForm.Header>
                </SForm.HeaderContainer>
                <SForm.FieldsContainer>
                    {pickUpAvailabilities.length > 0 && (
                        <div>
                            <Controller
                                name="pickUpTimeSlot"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <ReactHookSelect
                                        handleChange={(name, value) => {
                                            field.onChange({
                                                target: { name, value },
                                            });
                                        }}
                                        name={field.name}
                                        isDisabled={false}
                                        error={error ?? {}}
                                        labelName={intl.formatMessage(
                                            msg.form.pickUp,
                                        )}
                                        register={() => ({})} // No need to register, inside a Controller
                                        value={field.value}
                                        styles={{
                                            option: (provided: any) => ({
                                                ...provided,
                                                fontSize: '1.4rem',
                                            }),
                                            control: (styles: object) => ({
                                                ...styles,
                                                fontSize: '1.4rem',
                                            }),
                                            indicatorSeparator: (
                                                styles: object,
                                            ) => ({
                                                ...styles,
                                                display: 'none',
                                            }),
                                        }}
                                        selectOptions={pickUpAvailabilities.map(
                                            (p) => ({
                                                label: createLabel(
                                                    p,
                                                    showPrice,
                                                    intl.formatMessage(
                                                        msg.label.free,
                                                    ),
                                                    intl.formatMessage(
                                                        msg.label.full,
                                                    ),
                                                ),
                                                value: p.startTime,
                                                isDisabled: !p.isAvailable,
                                            }),
                                        )}
                                        labelRequired
                                        testId={`${MODULE_PREFIX}.pickUpTimeSlot`}
                                        placeholder={intl.formatMessage(
                                            msg.form.placeholder,
                                        )}
                                    />
                                )}
                            />
                        </div>
                    )}
                    {dropOffAvailabilities.length > 0 && (
                        <div>
                            <Controller
                                name="dropOffTimeSlot"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <ReactHookSelect
                                        handleChange={(name, value) => {
                                            field.onChange({
                                                target: { name, value },
                                            });
                                        }}
                                        name={field.name}
                                        isDisabled={false}
                                        error={error ?? {}}
                                        labelName={intl.formatMessage(
                                            msg.form.dropOff,
                                        )}
                                        register={() => ({})} // No need to register, inside a Controller
                                        value={field.value}
                                        selectOptions={dropOffAvailabilities.map(
                                            (p) => ({
                                                label: createLabel(
                                                    p,
                                                    showPrice,
                                                    intl.formatMessage(
                                                        msg.label.free,
                                                    ),
                                                    intl.formatMessage(
                                                        msg.label.full,
                                                    ),
                                                ),
                                                value: p.startTime,
                                                isDisabled: !p.isAvailable,
                                            }),
                                        )}
                                        styles={{
                                            option: (provided: any) => ({
                                                ...provided,
                                                fontSize: '1.4rem',
                                            }),
                                            control: (styles: object) => ({
                                                ...styles,
                                                fontSize: '1.4rem',
                                            }),
                                            indicatorSeparator: (
                                                styles: object,
                                            ) => ({
                                                ...styles,
                                                display: 'none',
                                            }),
                                        }}
                                        labelRequired
                                        testId={`${MODULE_PREFIX}.dropOffTimeSlot`}
                                        placeholder={intl.formatMessage(
                                            msg.form.placeholder,
                                        )}
                                    />
                                )}
                            />
                        </div>
                    )}
                    <SForm.ActionWrapper>
                        <SForm.StyledSecondaryButton
                            data-test-id={`${MODULE_PREFIX}-back`}
                            type="button"
                            onClick={goBack}
                        >
                            {intl.formatMessage(globalMsg.buttons.back)}
                        </SForm.StyledSecondaryButton>
                        <SForm.StyledPrimaryButton
                            data-test-id={`${MODULE_PREFIX}-save`}
                            type="submit"
                        >
                            {intl.formatMessage(msg.buttons.continue)}
                        </SForm.StyledPrimaryButton>
                    </SForm.ActionWrapper>
                    {isError && (
                        <SForm.StyledNotificationBanner
                            message={
                                <NotificationBannerMessage
                                    header={intl.formatMessage(
                                        msg.formError.header,
                                    )}
                                    content={intl.formatMessage(
                                        msg.formError.body,
                                    )}
                                />
                            }
                            type={'error'}
                            isClosable={false}
                            notificationIconSize="medium1"
                            hasBorder
                            isNotificationIconFilled
                        />
                    )}
                </SForm.FieldsContainer>
            </B2C.LoaderContainer>
        </SForm.StyledForm>
    );
};
