import styled from 'styled-components';

import { ContainedModal } from 'src/components/shared/ContainedModal';

export const Container = styled.div`
    font-size: 1rem;
    margin: auto auto 2.5rem auto;
`;

export const Header = styled.div`
    position: relative;
    display: flex;
    font-size: 2rem;
    padding-bottom: 1.5rem;
    margin: 0;
`;

const defaultSpan = styled.span`
    padding-bottom: 0.5rem;
`;

export const Title = styled(defaultSpan)`
    flex-basis: 95%;
    font-size: 1em;
    font-weight: bold;
    padding-bottom: 0.5rem;
`;

export const StyleContainedModal = styled(ContainedModal)`
    & > button {
        margin-top: 0.2rem;
    }
    .rc-dialog {
        width: 100% !important;
        .rc-dialog-body {
            font-size: 1.4rem;
            a {
                color: ${({ theme: { colors } }) => colors.secondaryOrange};
            }
        }
    }
`;
