import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'cosmos-components';
import { getScreenBreaks } from 'src/utils/htmlHelper';

export const StyledForm = styled.form`
    padding: 0;
    margin: auto;
    padding-bottom: 6rem;
`;

export const ActionWrapper = styled.div`
    margin-top: 1.5rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        flex-direction: column;
    }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    margin: 1.6rem 0;
    width: 49%;

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        width: 100%;
        order: 1;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
    color: ${({ theme: { colors } }) => colors.secondaryOrange};
    border: 1px solid ${({ theme: { colors } }) => colors.secondaryOrange};
    margin: 1.6rem 0;
    width: 49%;

    &:hover,
    &:focus,
    &:active {
        color: ${({ theme: { colors } }) => colors.secondaryOrange};
        border-color: ${({ theme: { colors } }) => colors.secondaryOrange};
        background-color: transparent;
    }

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        width: 100%;
        order: 2;
        margin: 0;
    }
`;

export const VideosFormHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 1.5rem;
    padding: 0.8rem 0rem;
    border-bottom: 2px solid
        ${({ theme: { colors } }) => colors.secondaryOrange};

    h3:first-child {
        margin: 0;
        text-align: justify;
        text-justify: inter-word;
        margin-right: 3rem;
    }

    h3:last-child {
        margin: 0;
        text-align: right;
        text-wrap: nowrap;
`;

export const VideoContainer = styled.div`
    margin: auto auto 5rem auto;
    width: 100%;
`;

export const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio (9 / 16 = 0.5625) */
    /* For 4:3 use 75% (3/4 = 0.75) */
`;

export const ResponsiveIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;
