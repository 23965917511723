import { defineMessages } from 'react-intl';

const timeSlots = defineMessages({
    pickUpTime: {
        id: 'registrationCompletion.timeSlots.pickUpTime',
        defaultMessage: 'Pick Up Time:',
    },
    dropOffTime: {
        id: 'registrationCompletion.timeSlots.dropOffTime',
        defaultMessage: 'Drop Off Time:',
    },
});

const registratonCompletionMessages = {
    timeSlots,
};

export default registratonCompletionMessages;
