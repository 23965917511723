export const MOCK_RES = {
    AvailableTimeslots: [
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: true,
            startTime: '09:00:00',
            endTime: '09:30:00',
            price: {
                value: 75.2131231231,
                currency: 'AUD',
            },
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: true,
            startTime: '09:30:00',
            endTime: '10:00:00',
            price: {
                value: 150.123213,
                currency: 'AUD',
            },
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: false,
            isFull: true,
            startTime: '20:00:00',
            endTime: '20:30:00',
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: true,
            startTime: '21:00:00',
            endTime: '21:30:00',
        },
    ],
    AllFreetimeSlots: [
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: true,
            startTime: '09:00:00',
            endTime: '09:30:00',
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: true,
            startTime: '09:30:00',
            endTime: '10:00:00',
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: false,
            startTime: '20:00:00',
            endTime: '20:30:00',
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: true,
            startTime: '21:00:00',
            endTime: '21:30:00',
        },
    ],
    AllUnavailableTimeslots: [
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: false,
            startTime: '09:00:00',
            endTime: '09:30:00',
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: false,
            startTime: '09:30:00',
            endTime: '10:00:00',
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: false,
            startTime: '20:00:00',
            endTime: '20:30:00',
        },
        {
            id: {
                organizationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                timeSlotId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            },
            isAvailable: false,
            startTime: '21:00:00',
            endTime: '21:30:00',
        },
    ],
    EmptyTimeslots: [],
    AssociatedTimeSlots: {
        pickUpTimeSlot: {
            _id: {
                organizationId: '98eb24cc-ec7a-4f22-84a5-6b8209d3d166',
                timeSlotId: 'fc48756a-3703-4aa5-891e-08c61a7c4003',
            },
            startTime: '09:00:00',
            endTime: '09:30:00',
        },
        dropOffTimeSlot: {
            _id: {
                organizationId: '98eb24cc-ec7a-4f22-84a5-6b8209d3d166',
                timeSlotId: 'fc48756a-3703-4aa5-891e-08c61a7c4003',
            },
            startTime: '21:00:00',
            endTime: '21:30:00',
        },
    },
    NoAssociatedTimeSlots: {
        pickUpTimeSlot: null,
        dropOffTimeSlot: null,
    },
};
