import { defineMessages } from 'react-intl';

const fields = defineMessages({
    bookingReference: {
        id: 'retrieveSelfCheckIn-formField-bookingReference',
        defaultMessage: 'Booking Reference',
    },
    lastName: {
        id: 'retrieveSelfCheckIn-formField-lastName',
        defaultMessage: 'Last Name',
    },
    enterLastName: {
        id: 'retrieveSelfCheckIn-formField-enterLastName',
        defaultMessage: 'Please enter last name',
    },
    enterBookingReference: {
        id: 'retrieveSelfCheckIn-formField-enterbookingreference',
        defaultMessage: 'Please enter booking reference',
    },
});

const buttons = defineMessages({
    continue: {
        id: 'retrieveSelfCheckIn.buttons.continueButton',
        defaultMessage: 'Save & Continue',
    },
});

const content = defineMessages({
    modalInfo: {
        id: 'retrieveSelfCheckIn.content.modalInfo',
        defaultMessage: `Please make sure your Booking reference has a suffix (If you don't know the suffix of your booking, please try "-1").
            You can also use your Agent reference in case you don’t have Booking reference handy.`,
    },
    modalInfoCA: {
        id: 'retrieveSelfCheckIn.content.modalInfoCA',
        defaultMessage: `Please make sure your Booking reference has a suffix (If you don't know the suffix of your booking, please try "-1").
            You can also use your Tour Operator reference in case you don’t have Booking reference handy.`,
    },
    thanksForChoosing: {
        id: 'retrieveSelfCheckIn.content.ThanksForChoosing',
        defaultMessage: 'Thanks for choosing to travel with',
    },
    thl: {
        id: 'retrieveSelfCheckIn.content.Thl',
        defaultMessage: 'thl',
    },
    weNeed: {
        id: 'retrieveSelfCheckIn.content.WeNeed',
        defaultMessage:
            'We need to get a few quick details before your arrival to prepare and get you on the road smoothly and quickly.',
    },
    toGetStarted: {
        id: 'retrieveSelfCheckIn.content.ToGetStarted',
        defaultMessage:
            'To get started, enter your booking reference and last name below. You can find these on your booking confirmation.',
    },
    usOutstandingBalance: {
        id: 'retrieveSelfCheckIn.content.usOutStandingBalance',
        defaultMessage:
            'Please note, when completing the self check in process, you will be prompted to pay any outstanding balances owing on your booking.  You should complete the check in process 14 days before you are due to travel.',
    },
    welcomeTo: {
        id: 'retrieveSelfCheckIn.content.welcomeTo',
        defaultMessage: 'Welcome to Online Check-in!',
    },
    days: {
        id: 'retrieveSelfCheckIn.content.days',
        defaultMessage: '5 days',
    },
    checkInOpens: {
        id: 'retrieveSelfCheckIn.content.checkInOpens',
        defaultMessage: 'Check-in opens',
    },
    beforePickUp: {
        id: 'retrieveSelfCheckIn.content.beforePickUp',
        defaultMessage:
            'before pick-up and all Guests must complete check-in before arriving at our station.',
    },
    duringCheckIn: {
        id: 'retrieveSelfCheckIn.content.duringCheckIn',
        defaultMessage: 'During Online Check-in you will need to:',
    },
    contactDetails: {
        id: 'retrieveSelfCheckIn.content.contactDetails',
        defaultMessage: 'Provide your contact details.',
    },
    licenceDetails: {
        id: 'retrieveSelfCheckIn.content.licenceDetails',
        defaultMessage:
            'Provide the driver’s license details, for all drivers.',
    },
    additionalDrivers: {
        id: 'retrieveSelfCheckIn.content.additionalDrivers',
        defaultMessage: 'Add additional drivers.',
    },
    demoVideos: {
        id: 'retrieveSelfCheckIn.content.demoVideos',
        defaultMessage: 'Watch the demonstration videos:',
    },
    travelExtras: {
        id: 'retrieveSelfCheckIn.content.travelExtras',
        defaultMessage: 'Add Travel Extras to enhance your vacation.',
    },
    outstanding: {
        id: 'retrieveSelfCheckIn.content.outstanding',
        defaultMessage: 'Pay any outstanding balances.',
    },
    insurancePolicy: {
        id: 'retrieveSelfCheckIn.content.insurancePolicy',
        defaultMessage:
            'Read the Rental Agreement, Insurance Policy and other important documents.',
    },
    subTextCa: {
        id: 'retrieveSelfCheckIn.content.subTextCa',
        defaultMessage:
            'Guests must fully complete Online Check in for all selections to be saved, Note: Pick up and Drop off times, will be released unless the check in process completed.',
    },
});

const placeHolders = defineMessages({
    lastName: {
        id: 'retrieveSelfCheckIn.placeHolders.lastName',
        defaultMessage: 'E.g. Smith',
    },
    bookingReference: {
        id: 'retrieveSelfCheckIn.placeHolders.bookingReference',
        defaultMessage: 'E.g. ABC123456-1',
    },
});

const error = defineMessages({
    unableToRetrieve: {
        id: 'retrieveSelfCheckIn.error.unableToRetrieve',
        defaultMessage: 'Unable to retrieve your booking?',
    },
    tryThese: {
        id: 'retrieveSelfCheckIn.error.tryThese',
        defaultMessage: 'Try these trouble shooting suggestions:',
    },
    bookingReferenceApplicable: {
        id: `booking-reference-applicable`,
        defaultMessage: `• Check the booking reference (and suffix if
            applicable).`,
    },
    bookingSuffix: {
        id: 'retrieveSelfCheckIn.error',
        defaultMessage: `• If you don't know the suffix of your booking, please try "-1"`,
    },
    lastNameConfirmation: {
        id: 'retrieveSelfCheckIn.error.lastNameConfirmation',
        defaultMessage: `• Check that the spelling of your last name matches
        the booking confirmation.`,
    },
    pleaseContact: {
        id: 'retrieveSelfCheckIn.error.pleaseContact',
        defaultMessage: `• Please contact us using below details.`,
    },
    pleaseContactUs: {
        id: 'retrieveSelfCheckIn.error.pleaseContactUs',
        defaultMessage: `Please contact us using below details.`,
    },
    someThingWrong: {
        id: 'retrieveSelfCheckIn.error.someThingWrong',
        defaultMessage: `Oops, something went wrong.`,
    },
    makeChanges: {
        id: 'retrieveSelfCheckIn.error.makeChanges',
        defaultMessage: `If you would like to make any changes, please contact us using below details for assistance.`,
    },
    makeChangesWebChat: {
        id: 'retrieveSelfCheckIn.error.makeChangesWebChat',
        description: 'makeChangesWebChat',
        defaultMessage: `If you would like to make any changes, please contact us via Web Chat or the phone number below for assistance.`,
    },
    conflict: {
        id: 'retrieveSelfCheckIn.error.conflict',
        defaultMessage:
            'Looks like there is a change on your booking. Please contact us using the below details.',
    },
    conflictWithLink: {
        id: 'retrieveSelfCheckIn.error.conflictWithLink',
        defaultMessage:
            'Looks like there is a change on your booking. Please contact us <a>here</a> or using the below details.',
    },
    tooEarlyToStartSelfCheckIn: {
        id: 'retrieveSelfCheckIn.error.tooEarlyToStartSCI',
        defaultMessage: 'Oops you are too early!',
    },
    tooEarlyToStartSelfCheckInDescription: {
        id: 'retrieveSelfCheckIn.error.tooEarlyToStartSCIDescription',
        defaultMessage: 'Check in opens 5 days before your pick up date.',
    },
});

const success = defineMessages({
    registrationCompleted: {
        id: 'retrieveSelfCheckIn.success.registrationCompleted',
        defaultMessage: `Registration for this rental has already been
         completed.`,
    },
});

const retrieveSelfCheckInMessages = {
    fields,
    buttons,
    content,
    placeHolders,
    error,
    success,
};

export default retrieveSelfCheckInMessages;
