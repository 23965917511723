import { rest } from 'msw';

import { getApiBaseUrl } from 'src/utils/configStore';
import productHandlers from 'src/modules/products/shared/mock/products.api.mock';
import paymentHandlers from 'src/modules/payment/mock/payment.api.mock';
import sciContentHandlers from 'src/hooks/mock/useSciContent.mock';
import sciVideoContentHandlers from 'src/hooks/mock/useSciVideoContent.mock';
import shuttleHandlers from 'src/modules/updateCustomerInfo/shuttleService/mock/shuttleService.api.mock';
import timeSlotsHandlers from 'src/modules/timeslots/mock/timeslots.api.mock';

import * as addProductsApi from '../modules/addProducts/addProducts.api';

import { MOCK_RES } from './responses';

const delayDuration = 500;
const baseURL = getApiBaseUrl();

export const handlers = [
    rest.get(`${baseURL}${addProductsApi.retrieveChoices}`, (_, res, ctx) =>
        res(
            ctx.delay(delayDuration),
            ctx.status(200),
            ctx.json(MOCK_RES.GET_retrieveChoice),
        ),
    ),
    rest.post(`${baseURL}${addProductsApi.addProducts}`, (_, res, ctx) =>
        res(ctx.delay(delayDuration), ctx.status(200)),
    ),
    rest.post(`${baseURL}${addProductsApi.calculate}`, (_, res, ctx) =>
        res(ctx.delay(delayDuration), ctx.status(200)),
    ),
    ...productHandlers,
    ...paymentHandlers,
    ...sciContentHandlers,
    ...sciVideoContentHandlers,
    ...shuttleHandlers,
    ...timeSlotsHandlers,
];
