import { AxiosResponse } from 'axios';

import { SCIApi, selfCheckInRoute } from 'src/apis';

import {
    AvailabilityType,
    GetAssociatedTimeSlotsAvailabilitiesResponseType,
    PostAssociatedTimeSlotsAvailabilitiesPayload,
    TimeSlot,
} from '../timeslots.types';

export const timeSlotsApiRoute = `${selfCheckInRoute}/rentals/timeslots`;
export const timeSlotsAvailabilitiesApiRoute = `${timeSlotsApiRoute}/availabilities`;
export const associatedTimeSlotsAvailabilitiesRoute = `${selfCheckInRoute}/rentals/timeslots/associations`;

export const useGetTimeSlotsAvailabilitiesKey = 'getTimeSlotsAvailabilitiesKey';
export const useGetAssociatedTimeSlotsKey = 'getAssociatedTimeSlotsKey';

export const getTimeSlotsAvailabilities = async (
    availabilityType: AvailabilityType,
): Promise<TimeSlot[]> => {
    const apiCalls = SCIApi.getInstance();

    const axiosResult: AxiosResponse<TimeSlot[]> = await apiCalls.get<
        TimeSlot[]
    >(timeSlotsAvailabilitiesApiRoute, {
        type: availabilityType,
    });
    const result = axiosResult?.data ?? axiosResult;

    return result;
};

export const getAssociatedTimeSlotsAvailabilities =
    async (): Promise<GetAssociatedTimeSlotsAvailabilitiesResponseType> => {
        const apiCalls = SCIApi.getInstance();

        const axiosResult: AxiosResponse<GetAssociatedTimeSlotsAvailabilitiesResponseType> =
            await apiCalls.get<GetAssociatedTimeSlotsAvailabilitiesResponseType>(
                associatedTimeSlotsAvailabilitiesRoute,
            );
        const result = axiosResult?.data ?? axiosResult;

        return result;
    };

export const postAssociatedTimeSlotsAvailabilities = async (
    payload: PostAssociatedTimeSlotsAvailabilitiesPayload,
): Promise<GetAssociatedTimeSlotsAvailabilitiesResponseType> => {
    const apiCalls = SCIApi.getInstance();

    const axiosResult: AxiosResponse<GetAssociatedTimeSlotsAvailabilitiesResponseType> =
        await apiCalls.post<GetAssociatedTimeSlotsAvailabilitiesResponseType>(
            associatedTimeSlotsAvailabilitiesRoute,
            payload,
        );
    const result = axiosResult?.data ?? axiosResult;

    return result;
};
