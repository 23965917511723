import { all, put, takeLatest } from 'redux-saga/effects';
import {
    retrieveSelfCheckInAction,
    retrieveSelfCheckInActionByMagicTokenAction,
} from './retrieveSelfCheckIn.duck';
import { SCIApi, selfCheckInRoute } from 'src/apis';
import {
    SelfCheckIn,
    RetrieveSelfCheckInPayload,
    RetrieveSelfCheckInByMagicTokenPayload,
} from './types';
import * as api from './retrieveSelfCheckIn.api';
import {
    initialiseCarousel,
    setCarouselPageIsCompletedAction,
} from 'src/components/shared/SciPage/SciPage.duck';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { AvailabilityType, TimeSlot } from '../timeslots/timeslots.types';
import { getTimeSlotsAvailabilities } from '../timeslots/hooks/timeslots.api.hooks';
import { TimeSlots } from '../registerCompletion/registerCompletion.style';

const apiCalls = SCIApi.getInstance();

function* retrieveSelfCheckIn({
    payload,
}: {
    payload: RetrieveSelfCheckInPayload;
}) {
    try {
        const { token, ...trimedPayload } = payload;

        const data: SelfCheckIn = yield apiCalls.get<SelfCheckIn>(
            selfCheckInRoute,
            trimedPayload,
            {
                headers: {
                    'X-RecaptchaToken': payload?.token,
                },
            },
        );

        apiCalls.configureSessionId(data.sciSession.sessionId);

        let timeslotsAvailable = false;

        try {
            const pickUpTimeslots: TimeSlot[] =
                yield getTimeSlotsAvailabilities(AvailabilityType.pickUp);
            const dropOffTimeslots: TimeSlot[] =
                yield getTimeSlotsAvailabilities(AvailabilityType.dropOff);

            timeslotsAvailable =
                pickUpTimeslots.some((p) => p.isAvailable) ||
                dropOffTimeslots.some((p) => p.isAvailable);
        } catch (error) {
            // We do not want to stop user if no timeslots are available.
        }

        yield put(retrieveSelfCheckInAction.success(data));
        yield put(
            initialiseCarousel({
                orgId: data.id.organizationId,
                isRelocation: !!data?.agent?.type?.relocation,
                timeslotsAvailable,
            }),
        );

        yield put(
            setCarouselPageIsCompletedAction({
                page: SciPagesEnum.RetrieveSelfCheckInPage,
            }),
        );
    } catch (e) {
        yield put(retrieveSelfCheckInAction.failure(e));
    }
}

function* retrieveSelfCheckInByMagicToken({
    payload,
}: {
    payload: RetrieveSelfCheckInByMagicTokenPayload;
}) {
    try {
        const data: SelfCheckIn = yield apiCalls.get<SelfCheckIn>(
            api.selfCheckInByMagicTokenRoute,
            payload,
        );
        yield put(retrieveSelfCheckInAction.success(data));
        apiCalls.configureSessionId(data.sciSession.sessionId);

        yield put(
            setCarouselPageIsCompletedAction({
                page: SciPagesEnum.RetrieveSelfCheckInPage,
            }),
        );
    } catch (e) {
        yield put(retrieveSelfCheckInAction.failure(e));
    }
}

function* retrieveSelfCheckInSaga() {
    yield all([
        takeLatest(retrieveSelfCheckInAction, retrieveSelfCheckIn),
        takeLatest(
            retrieveSelfCheckInActionByMagicTokenAction,
            retrieveSelfCheckInByMagicToken,
        ),
    ]);
}

export default retrieveSelfCheckInSaga;
