import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { SCIApi, selfCheckInRoute } from 'src/apis';
import { useSciKey } from 'src/hooks/useSci';
import { VideoContent } from 'src/types/SciContent';

export const useAcceptVideo = () => {
    const queryClient = useQueryClient();

    const invalidateSciData = useCallback(() => {
        queryClient.invalidateQueries([useSciKey]);
    }, [queryClient]);

    const videoAcceptanceApiRoute = `${selfCheckInRoute}/contents/videos/accept`;
    const acceptVideo = async (video: VideoContent): Promise<void> => {
        const apiInstance = SCIApi.getInstance();
        await apiInstance.post(videoAcceptanceApiRoute, {
            key: video.key,
            title: video.title,
        });
    };

    const { mutate } = useMutation({
        mutationFn: acceptVideo,
    });

    return {
        mutate,
        invalidateSciData,
    };
};
