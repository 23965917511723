export enum SelfCheckInStatusEnum {
    Unspecified,
    InProgress,
    Done,
}

export enum HttpStatusCodeEnum {
    unKnown = 400,
    badRequest = 400,
    unAuthorized = 401,
    notFound = 404,
    conflict = 409,
    internalServerError = 500,
}

export enum UsBrandNames {
    elmonte = 'elmonterv.sci',
    roadBear = 'roadbearrv.sci',
}

export enum UkBrandNames {
    europe = 'eu.sci',
}

export enum CaBrandNames {
    canadream = 'canadream.sci',
}

export enum AuNzBrandNames {
    aunz = 'motek.sci',
}

export enum BrandNames {
    elmonte = UsBrandNames.elmonte,
    roadBear = UsBrandNames.roadBear,
    europe = UkBrandNames.europe,
    canadream = CaBrandNames.canadream,
    aunz = AuNzBrandNames.aunz,
}

export type AllBrands =
    | BrandNames
    | UsBrandNames
    | UkBrandNames
    | CaBrandNames
    | AuNzBrandNames;

export enum DeviceType {
    Mobile,
    Tablet,
    Desktop,
}
