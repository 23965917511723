import { defineMessages } from 'react-intl';

const content = defineMessages({
    expiredSession: {
        id: 'SciPage.content.expiredSession',
        defaultMessage: 'Your session has expired due to inactivity.',
    },
    expiredSessionSubText: {
        id: 'SciPage.content.expiredSessionSubText',
        defaultMessage:
            'Your session has timed out and any selections made have not been saved, please refresh and start again.',
    },
    expiryTimer: {
        id: 'SciPage.content.expiryTimer',
        defaultMessage: 'Your session will end in...',
    },
    conflictError: {
        id: 'SciPage.content.conflictError',
        defaultMessage:
            'Looks like there is a change on your booking. ' +
            'Please refresh your browser or click below to try again. If you are still experiencing an issue, please contact us using the below details.',
    },
    conflictErrorWithLink: {
        id: 'SciPage.content.conflictErrorWithLink',
        defaultMessage:
            'Looks like there is a change on your booking. ' +
            'Please refresh your browser or click below to try again. If you are still experiencing an issue, please contact us <a>here</a>',
    },
    genericError: {
        id: 'SciPage.content.genericError',
        defaultMessage:
            'Please refresh your browser or click below to try again. If you are still experiencing an issue, please contact us using the below details.',
    },
    genericErrorWithLink: {
        id: 'SciPage.content.genericErrorWithLink',
        defaultMessage:
            'Please refresh your browser or click below to try again. If you are still experiencing an issue, please contact us <a>here</a>',
    },
    QFFDisclamer: {
        id: 'SciPage.content.QFFDisclamer',
        defaultMessage:
            'Points earned is an estimate only. Total points earned is determined by actual dates travelled and amount spent.',
    },
});

const buttons = defineMessages({
    back: {
        id: `SciPage.buttons.back`,
        defaultMessage: 'Back To Home',
    },
    tryAgain: {
        id: `SciPage.buttons.tryAgain`,
        defaultMessage: 'Try Again',
    },
});

const sciPageMessages = {
    content,
    buttons,
};

export default sciPageMessages;
