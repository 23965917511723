import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { B2C, CosmosEnums } from 'cosmos-components';

import { sciContentKeys } from 'src/constants';
import { UkBrandNames, UsBrandNames, CaBrandNames } from 'src/enums';
import { useSciContent } from 'src/hooks/useSciContent';
import { useSci } from 'src/hooks/useSci';
import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import {
    Header,
    EMHeader,
    RBHeader,
    UKHeader,
    CAHeader,
} from 'src/components/shared/Header';
import { Footer } from 'src/components/shared/Footer';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { useConfiguration } from 'src/hooks/useConfiguration';

import { useGetAssociatedTimeSlots } from '../timeslots/hooks/timeslots.form.hooks';
import { createLabel } from '../timeslots/timeslots.utils';
import { PaymentMethodEnum } from '../payment/payment.enum';

import { registerCompletionAction } from './registerCompletion.duck';
import * as S from './registerCompletion.style';
import msg from './registrationCompletion.messages';

const MODULE_PREFIX = 'register-completion';
export const componentRegisterCompletion = `${MODULE_PREFIX}-component`;

export const RegisterCompletionComponent: FC = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { deviceType } = useWindowDimensions();
    const configuration = useConfiguration();
    const brandName = useSelector(sciBrandNameSelector);
    const isRoadBear = brandName === UsBrandNames.roadBear;
    const isElmonte = brandName === UsBrandNames.elmonte;
    const isEu = brandName === UkBrandNames.europe;
    const isCa = brandName === CaBrandNames.canadream;

    const query = new URLSearchParams(window.location.search);
    const paymentMethod = query.get('paymentMethod');

    const {
        data: sciBankTransferConfirmation,
        isLoading: sciBankTransferConfirmationIsLoading,
    } = useSciContent(sciContentKeys.sciBankTransferConfirmation);

    const {
        data: sciOnlinePaymentConfirmation,
        isLoading: sciOnlinePaymentConfirmationIsLoading,
    } = useSciContent(sciContentKeys.sciOnlinePaymentConfirmation);

    const {
        isLoading: isSciLoading,
        data: sciData,
        primaryProductInfoData,
        isBookingSummaryLoading,
        bookingSummaryData,
    } = useSci();

    const { data: associatedTimeSlots } = useGetAssociatedTimeSlots();

    const sciConfirmationHtml =
        paymentMethod === PaymentMethodEnum.CreditCard ||
        paymentMethod === PaymentMethodEnum.DebitCard
            ? sciOnlinePaymentConfirmation
            : sciBankTransferConfirmation;

    const computedBookingSummaryLoading =
        isSciLoading ||
        isBookingSummaryLoading ||
        sciBankTransferConfirmationIsLoading ||
        sciOnlinePaymentConfirmationIsLoading;

    const isRelocationAgent = sciData?.agent?.type?.relocation;
    const hideDynamicLiability =
        [...Object.values(UsBrandNames)].includes(brandName as UsBrandNames) ||
        [...Object.values(CaBrandNames)].includes(brandName as CaBrandNames) ||
        isRelocationAgent;

    useEffect(() => {
        if (sciData?.rental?.id?.rentalId) {
            dispatch(
                registerCompletionAction({
                    rentalId: sciData?.rental?.id?.rentalId,
                }),
            );
        }
    }, [dispatch, sciData?.rental?.id?.rentalId]);

    return (
        <S.StyledPage
            header={
                isRoadBear ? (
                    <RBHeader />
                ) : isElmonte ? (
                    <EMHeader />
                ) : isEu ? (
                    <UKHeader />
                ) : isCa ? (
                    <CAHeader />
                ) : (
                    <Header />
                )
            }
            footer={<Footer />}
        >
            <S.PageContainer
                deviceType={deviceType}
                data-test-id={componentRegisterCompletion}
            >
                {computedBookingSummaryLoading ? (
                    <B2C.LoaderSpinner size="large" />
                ) : (
                    <>
                        <S.ChildrenContainer deviceType={deviceType}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sciConfirmationHtml ?? '',
                                }}
                            />
                            {(associatedTimeSlots.dropOffTimeSlot?.startTime ||
                                associatedTimeSlots.pickUpTimeSlot
                                    ?.startTime) && (
                                <S.TimeSlotsContainer>
                                    {associatedTimeSlots.pickUpTimeSlot
                                        ?.startTime && (
                                        <S.TimeSlots>
                                            {`${intl.formatMessage(
                                                msg.timeSlots.pickUpTime,
                                            )}
                                                ${createLabel(
                                                    associatedTimeSlots.pickUpTimeSlot,
                                                    false,
                                                    '',
                                                    '',
                                                )}
                                            `}
                                        </S.TimeSlots>
                                    )}
                                    {associatedTimeSlots.dropOffTimeSlot
                                        ?.startTime && (
                                        <S.TimeSlots>
                                            {`${intl.formatMessage(
                                                msg.timeSlots.dropOffTime,
                                            )}
                                                ${createLabel(
                                                    associatedTimeSlots.dropOffTimeSlot,
                                                    false,
                                                    '',
                                                    '',
                                                )}
                                            `}
                                        </S.TimeSlots>
                                    )}
                                </S.TimeSlotsContainer>
                            )}
                        </S.ChildrenContainer>
                        {sciData && (
                            <S.BookingSummaryContainer>
                                <B2C.BookingSummary
                                    countryCode={
                                        sciData.countryCode as CosmosEnums.CountryCode
                                    }
                                    summary={bookingSummaryData}
                                    name={
                                        sciData.rental?.primaryProductName ?? ''
                                    }
                                    pickUpLocation={
                                        sciData.rental?.start?.point.name ?? ''
                                    }
                                    pickUpDate={
                                        sciData.rental?.start?.date ?? ''
                                    }
                                    dropOffLocation={
                                        sciData.rental?.end?.point.name ?? ''
                                    }
                                    dropOffDate={
                                        sciData.rental?.end?.date ?? ''
                                    }
                                    reference={sciData.rental?.reference ?? ''}
                                    pickUpTimezone={
                                        sciData.rental?.start?.point?.timeZone
                                    }
                                    dropOffTimezone={
                                        sciData.rental?.end?.point?.timeZone
                                    }
                                    group={sciData.rental.group}
                                    imageUrl={
                                        primaryProductInfoData?.defaultImageUrl ??
                                        ''
                                    }
                                    showPassengerInfo
                                    numberOfAdults={
                                        sciData.rental?.numberOfAdults
                                    }
                                    numberOfChildren={
                                        sciData.rental?.numberOfChildren
                                    }
                                    hideDynamicLiability={hideDynamicLiability}
                                    showTaxBreakdown={
                                        configuration.functionalities
                                            .taxBreakDown.active
                                    }
                                    loading={isBookingSummaryLoading}
                                />
                            </S.BookingSummaryContainer>
                        )}
                    </>
                )}
            </S.PageContainer>
        </S.StyledPage>
    );
};
