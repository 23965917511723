import { all, put, takeLatest } from 'redux-saga/effects';

import { Point } from 'src/types/Point';

import { updateCustomerInfoAction } from './updateCustomerInfo.duck';
import {
    retrieveCustomerInfoAction,
    createShuttleAction,
    deleteShuttleAction,
} from './retrieveCustomerInfo.duck';
import { SCIApi } from 'src/apis';
import * as api from './updateCustomerInfo.api';
import {
    CustomerInfo,
    GetOrganizationSettingsPayload,
    OrganizationSettings,
    ShuttleInfo,
    ShuttleTransit,
    UpdateCustomerInfoPayload,
} from './types';
import { setCarouselPageIsCompletedAction } from 'src/components/shared/SciPage/SciPage.duck';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { Countries } from './types';
import { retrieveCountriesAction } from './retrieveCountries.duck';
import { Product } from '../addProducts/types';
import { retrievePrimaryProductInfoAction } from './retrievePrimaryProductInfo.duck';
import { retrieveSelfCheckInAction } from '../retrieveSelfCheckIn/retrieveSelfCheckIn.duck';
import { retrieveOrganizationSettingsAction } from './retrieveOrganizationSettings.duck';
import { formatShuttleKey } from './utils';

const apiCalls = SCIApi.getInstance();

function* updateCustomerInfo({
    payload,
}: {
    payload: UpdateCustomerInfoPayload;
}) {
    try {
        const data: UpdateCustomerInfoPayload = yield apiCalls.post(
            api.updateCustomerInfo,
            payload,
        );
        yield put(updateCustomerInfoAction.success(data));
        yield put(retrieveCustomerInfoAction.success(data.hirer));
        data.rental.end = payload.rental.end;
        data.rental.start = payload.rental.start;
        yield put(
            retrieveSelfCheckInAction.success({
                hirer: data.hirer,
                rental: data.rental,
            }),
        );

        yield put(
            setCarouselPageIsCompletedAction({
                page: SciPagesEnum.UpdateCustomerPage,
            }),
        );
    } catch (e) {
        yield put(updateCustomerInfoAction.failure(e));
    }
}

function* getCustomerInfo() {
    try {
        const customerInfo: CustomerInfo = yield apiCalls.get<CustomerInfo>(
            api.getCustomerInfo,
        );

        const shuttleTransit: ShuttleTransit =
            yield apiCalls.get<ShuttleTransit>(api.shuttleTransitCrud);

        const data: CustomerInfo & ShuttleInfo = {
            ...customerInfo,
            isShuttleSelected: !!shuttleTransit?.start?.point?.name,
            shuttleSelected: !!shuttleTransit?.start?.point?.name
                ? formatShuttleKey(shuttleTransit?.start?.point)
                : '',
        };

        yield put(retrieveCustomerInfoAction.success(data));
    } catch (error) {
        yield put(retrieveCustomerInfoAction.failure(error));
    }
}

function* getCountries() {
    try {
        const data: Countries = yield apiCalls.get<Countries>(api.getCountries);
        yield put(retrieveCountriesAction.success(data));
    } catch (error) {
        yield put(retrieveCountriesAction.failure(error));
    }
}

function* getDefaultOrganizationCountryCode({
    payload,
}: {
    payload: GetOrganizationSettingsPayload;
}) {
    try {
        const data: OrganizationSettings =
            yield apiCalls.get<OrganizationSettings>(
                api.getDefaultOrganizationCountryCodePath(
                    payload.organizationId,
                ),
            );
        yield put(retrieveOrganizationSettingsAction.success(data));
    } catch (error) {
        yield put(retrieveOrganizationSettingsAction.failure(error));
    }
}

function* getPrimaryProductInfo() {
    try {
        const data: Product = yield apiCalls.get<Product>(
            api.getPrimaryProductInfo,
        );
        yield put(retrievePrimaryProductInfoAction.success(data));
    } catch (error) {
        yield put(retrievePrimaryProductInfoAction.failure(error));
    }
}

function* deleteShuttle() {
    try {
        yield apiCalls.delete(api.shuttleTransitCrud);
        yield put(deleteShuttleAction.success());
    } catch (error) {
        yield put(deleteShuttleAction.failure(error));
    }
}

function* createShuttle({ payload }: { payload: Point }) {
    try {
        yield apiCalls.post(api.shuttleTransitCrud, payload);
        yield put(deleteShuttleAction.success());
    } catch (error) {
        yield put(deleteShuttleAction.failure(error));
    }
}

function* updateCustomerInfoSaga() {
    yield all([
        takeLatest(updateCustomerInfoAction, updateCustomerInfo),
        takeLatest(retrieveCustomerInfoAction, getCustomerInfo),
        takeLatest(retrieveCountriesAction, getCountries),
        takeLatest(retrievePrimaryProductInfoAction, getPrimaryProductInfo),
        takeLatest(
            retrieveOrganizationSettingsAction,
            getDefaultOrganizationCountryCode,
        ),
        takeLatest(deleteShuttleAction, deleteShuttle),
        takeLatest(createShuttleAction, createShuttle),
    ]);
}

export default updateCustomerInfoSaga;
