import styled from 'styled-components';
import {
    NotificationBanner,
    PrimaryButton,
    SecondaryButton,
} from 'cosmos-components';

import { getScreenBreaks } from 'src/utils/htmlHelper';

export const StyledForm = styled.form``;

export const HeaderContainer = styled.div`
    border-bottom: 2px solid
        ${({ theme: { colors } }) => colors.secondaryOrange};
`;

export const Header = styled.h3`
    font-size: 1.4rem;
    margin: 1rem 0;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 0;

    label {
        display: block;
    }
`;

export const ActionWrapper = styled.div`
    margin-top: 1.5rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        flex-direction: column;
    }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    margin: 1.6rem 0;
    width: 49%;
    align-self: center;

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        width: 100%;
        order: 1;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
    position: relative;
    color: ${({ theme: { colors } }) => colors.secondaryOrange};
    border: 1px solid ${({ theme: { colors } }) => colors.secondaryOrange};
    margin: 1.6rem 0;
    width: 49%;

    &:hover,
    &:focus,
    &:active {
        color: ${({ theme: { colors } }) => colors.secondaryOrange};
        border-color: ${({ theme: { colors } }) => colors.secondaryOrange};
        background-color: transparent;
    }

    @media (max-width: ${(props) => getScreenBreaks(props).screenXSMax}) {
        width: 100%;
        order: 2;
        margin: 0;
    }
`;

export const StyledNotificationBanner = styled(NotificationBanner)`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`;
