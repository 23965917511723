import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { SciContent } from 'src/components/shared/SciContent';
import { SciPage } from 'src/components/shared/SciPage';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import {
    setCarouselDotsCurrentPage,
    setCarouselPageIsCompletedAction,
} from 'src/components/shared/SciPage/SciPage.duck';
import { sciContentKeys } from 'src/constants';

import { MODULE_PREFIX } from './timeslots.constants';
import msg from './timeslots.messages';
import * as S from './timeslots.style';
import { TimeSlotsForm } from './timeslots.form';

export const componentTimeSlots = `${MODULE_PREFIX}-component`;

export const TimeSlotsComponent: FC = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        dispatch(setCarouselDotsCurrentPage(SciPagesEnum.TimeSlots));
    }, [dispatch]);

    return (
        <SciPage>
            <S.Container data-test-id={componentTimeSlots}>
                <S.Header>
                    <S.Title>{intl.formatMessage(msg.pageItems.title)}</S.Title>
                    <S.StyleContainedModal
                        dialogInfo={
                            <SciContent
                                sciContentKey={
                                    sciContentKeys.sciInfoBoxTimeSlots
                                }
                            />
                        }
                    />
                </S.Header>
                <SciContent sciContentKey={sciContentKeys.sciTimeslotsPage} />
            </S.Container>
            <TimeSlotsForm
                successCallback={() => {
                    dispatch(
                        setCarouselPageIsCompletedAction({
                            page: SciPagesEnum.TimeSlots,
                        }),
                    );
                }}
            />
        </SciPage>
    );
};
