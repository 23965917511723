import {
    StyledContentWrapper,
    StyledContent,
} from './retrieveSelfCheckIn.component';
import { useIntl } from 'react-intl';
import msg from './retrieveSelfCheckInMessages';

export const LandingTextCA = () => {
    const intl = useIntl();

    return (
        <StyledContentWrapper>
            <StyledContent>
                <b>{intl.formatMessage(msg.content.welcomeTo)}</b>
            </StyledContent>
            <StyledContent>
                {intl.formatMessage(msg.content.checkInOpens)}{' '}
                <b>{intl.formatMessage(msg.content.days)}</b>{' '}
                {intl.formatMessage(msg.content.beforePickUp)}
            </StyledContent>
            <StyledContent></StyledContent>
            <StyledContent>
                <b>{intl.formatMessage(msg.content.duringCheckIn)}</b>
                <ul>
                    <li>{intl.formatMessage(msg.content.contactDetails)}</li>
                    <li>{intl.formatMessage(msg.content.licenceDetails)}</li>
                    <li>{intl.formatMessage(msg.content.additionalDrivers)}</li>
                    <li>{intl.formatMessage(msg.content.demoVideos)}</li>
                    <li>{intl.formatMessage(msg.content.travelExtras)}</li>
                    <li>{intl.formatMessage(msg.content.outstanding)}</li>
                    <li>{intl.formatMessage(msg.content.insurancePolicy)}</li>
                </ul>
            </StyledContent>
            <StyledContent>
                {intl.formatMessage(msg.content.subTextCa)}
            </StyledContent>
        </StyledContentWrapper>
    );
};
