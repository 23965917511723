import moment from 'moment';

import { CosmosEnums } from 'cosmos-components';

import { currencySymbols } from 'src/constants';

import {
    TIMESLOTS_FORMAT,
    TIMESLOTS_RECEIVED_FORMAT,
} from './timeslots.constants';
import { TimeSlot } from './timeslots.types';

export const createLabel = (
    timeSlot: TimeSlot,
    showPrice: boolean,
    freeLabel: string,
    fullLabel: string,
): string => {
    const startTime = moment(timeSlot.startTime, [
        TIMESLOTS_RECEIVED_FORMAT,
    ]).format(TIMESLOTS_FORMAT);

    const endTime = moment(timeSlot.endTime, [
        TIMESLOTS_RECEIVED_FORMAT,
    ]).format(TIMESLOTS_FORMAT);

    const time = `${startTime} - ${endTime}`;

    if (timeSlot.isFull) {
        return `${time} (${fullLabel})`;
    }
    if (!showPrice) {
        return time;
    }

    if (!timeSlot.price || timeSlot.price.value <= 0) {
        return `${time} (${freeLabel})`;
    }

    const currencySymbol =
        currencySymbols[timeSlot.price.currency ?? CosmosEnums.Currencies.NZD];

    const price = timeSlot.price.value.toFixed(2);

    return `${time} (${currencySymbol}${price})`;
};
