import { merge } from 'lodash';

import globalConfig from 'src/config/global.json';
import _canadreamConfig from 'src/config/canadream.json';
import _elmonteConfig from 'src/config/elmonte.json';
import _roadbearConfig from 'src/config/roadbear.json';
import _euConfig from 'src/config/eu.json';
import _aunzConfig from 'src/config/aunz.json';

import {
    UkBrandNames,
    UsBrandNames,
    CaBrandNames,
    AuNzBrandNames,
} from 'src/enums';

const canadreamConfig = merge({}, globalConfig, _canadreamConfig);
const elmonteConfig = merge({}, globalConfig, _elmonteConfig);
const roadbearConfig = merge({}, globalConfig, _roadbearConfig);
const euConfig = merge({}, globalConfig, _euConfig);
const aunzConfig = merge({}, globalConfig, _aunzConfig);

export const useConfiguration = () => {
    const currentURL = window?.location?.href;
    const lowerCaseUrl = currentURL.toLocaleLowerCase();

    if (lowerCaseUrl.includes(UsBrandNames.roadBear)) {
        return roadbearConfig;
    }
    if (lowerCaseUrl.includes(UsBrandNames.elmonte)) {
        return elmonteConfig;
    }
    if (lowerCaseUrl.includes(UkBrandNames.europe)) {
        return euConfig;
    }
    if (lowerCaseUrl.includes(CaBrandNames.canadream)) {
        return canadreamConfig;
    }
    if (lowerCaseUrl.includes(AuNzBrandNames.aunz)) {
        return aunzConfig;
    }

    return globalConfig;
};
