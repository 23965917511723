import { useEffect, useState } from 'react';
import { Control, FieldValues, FormState, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    UseMutateFunction,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useBookingSummaryKey } from 'src/hooks/useBookingSummary';

import {
    AvailabilityType,
    GetAssociatedTimeSlotsAvailabilitiesResponseType,
    PostAssociatedTimeSlotsAvailabilitiesPayload,
} from '../timeslots.types';
import messages from '../timeslots.messages';
import {
    getAssociatedTimeSlotsAvailabilities,
    postAssociatedTimeSlotsAvailabilities,
    useGetAssociatedTimeSlotsKey,
    useGetTimeSlotsAvailabilitiesKey,
} from './timeslots.api.hooks';
import { useTimeSlotsAssociations } from './timeslots.hooks';

interface UseGetAssociatedTimeSlots {
    isLoading: boolean;
    data: GetAssociatedTimeSlotsAvailabilitiesResponseType;
}

export const useGetAssociatedTimeSlots = (): UseGetAssociatedTimeSlots => {
    const { isLoading, isFetching, isRefetching, data } = useQuery({
        queryKey: [useGetAssociatedTimeSlotsKey],
        queryFn: async () => await getAssociatedTimeSlotsAvailabilities(),
    });

    return {
        isLoading: isLoading || isFetching || isRefetching,
        data: data ?? {
            dropOffTimeSlot: null,
            pickUpTimeSlot: null,
        },
    };
};

interface UsePostAssociatedTimeSlots {
    mutate: UseMutateFunction<
        GetAssociatedTimeSlotsAvailabilitiesResponseType,
        unknown,
        PostAssociatedTimeSlotsAvailabilitiesPayload,
        unknown
    >;
    isLoading: boolean;
    isError: boolean;
}

const usePostAssociatedTimeSlots = (): UsePostAssociatedTimeSlots => {
    const { mutate, isLoading, isError } = useMutation(
        postAssociatedTimeSlotsAvailabilities,
    );

    return {
        mutate,
        isLoading,
        isError,
    };
};

interface UseTimeSlotsForm {
    control: Control<FieldValues, any>;
    formState: FormState<FieldValues>;
    showPrice: boolean;
    isError: boolean;
    isLoading: boolean;
    onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

export const useTimeSlotsForm = (
    successCallback: () => void,
): UseTimeSlotsForm => {
    const intl = useIntl();
    const queryClient = useQueryClient();
    const {
        dropOffAvailabilities,
        pickUpAvailabilities,
        isLoading: useTimeSlotsAssociationsIsLoading,
    } = useTimeSlotsAssociations();
    const { data: associatedTimeSlots } = useGetAssociatedTimeSlots();

    const [showPrice, setShowPrice] = useState(true);

    const fieldsSchema = yup.object().shape({
        ...(pickUpAvailabilities.length > 0
            ? {
                  pickUpTimeSlot: yup
                      .string()
                      .required(intl.formatMessage(messages.formError.pickUp)),
              }
            : {}),
        ...(dropOffAvailabilities.length > 0
            ? {
                  dropOffTimeSlot: yup
                      .string()
                      .required(intl.formatMessage(messages.formError.dropOff)),
              }
            : {}),
    });

    const { handleSubmit, control, setValue, formState, watch, trigger } =
        useForm({
            resolver: yupResolver(fieldsSchema),
            mode: 'onChange',
        });

    const formValues = watch();

    const { mutate, isError, isLoading } = usePostAssociatedTimeSlots();

    useEffect(() => {
        if (useTimeSlotsAssociationsIsLoading) {
            return;
        }
        if (associatedTimeSlots.pickUpTimeSlot?.startTime) {
            setValue(
                'pickUpTimeSlot',
                associatedTimeSlots.pickUpTimeSlot?.startTime,
            );
            trigger();
        } else {
            setValue('pickUpTimeSlot', null);
        }
    }, [associatedTimeSlots.pickUpTimeSlot, useTimeSlotsAssociationsIsLoading]);

    useEffect(() => {
        if (useTimeSlotsAssociationsIsLoading) {
            return;
        }
        if (associatedTimeSlots.dropOffTimeSlot?.startTime) {
            setValue(
                'dropOffTimeSlot',
                associatedTimeSlots.dropOffTimeSlot?.startTime,
            );
            trigger();
        } else {
            setValue('dropOffTimeSlot', null);
        }
    }, [
        associatedTimeSlots.dropOffTimeSlot,
        useTimeSlotsAssociationsIsLoading,
    ]);

    useEffect(() => {
        setShowPrice(
            pickUpAvailabilities?.some((timeslot) => !!timeslot.price) ||
                dropOffAvailabilities?.some((timeslot) => !!timeslot.price),
        );
    }, [pickUpAvailabilities, dropOffAvailabilities]);

    useEffect(() => {
        const subscription = watch((data, { name, type }) => {
            if (type === 'change' && name) {
                const isPickup = name === 'pickUpTimeSlot';
                const selectedAvailability = (
                    isPickup ? pickUpAvailabilities : dropOffAvailabilities
                ).find((a) => a.startTime === data[name]);

                if (selectedAvailability && data[name] !== formValues[name]) {
                    mutate(
                        {
                            timeSlot: selectedAvailability,
                            type: isPickup
                                ? AvailabilityType.pickUp
                                : AvailabilityType.dropOff,
                        },
                        {
                            onSuccess: () => {
                                queryClient.invalidateQueries([
                                    useBookingSummaryKey,
                                ]);
                            },
                            onError: () => {
                                queryClient.invalidateQueries([
                                    useGetTimeSlotsAvailabilitiesKey,
                                ]);

                                setValue(name, null);
                            },
                        },
                    );
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, pickUpAvailabilities, dropOffAvailabilities, formValues]);

    const onSubmit = handleSubmit(() => {
        successCallback();
    });

    return {
        control,
        formState,
        showPrice,
        isError,
        isLoading,
        onSubmit,
    };
};
