import {
    ProductsResponse as ProductsResponseV2,
    ConfigurationNodeGroup,
} from 'src/modules/products/shared/products.types';

export const MOCK_RES = {
    category: 'Insurances',
    groups: [
        {
            id: {
                organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                configurationNodeGroupId:
                    '170d71cb-3e22-4a34-8230-4399743dd45f',
            },
            name: 'OWN_INS',
            title: '<div><h2 style="line-height: 30px; color: #1A1A1A; font-weight: 600;">Do you have your own insurance plan to cover this rental?</h2></div>',
            description:
                '<div><p>As a minimum, you must be covered up to state minimums, either through:</p><ul><li>Your own personal insurance policy (contact your insurance carrier to see if the rental is covered by your plan),</li><li>Or by adding our Reduced  Liability Insurance (RLI) product.</li></ul><p>Note: we offer additional products which provide further peace of mind during your rental.</p></div>',
            order: 1,
            required: true,
            multiSelect: false,
            display: 'Horizontally',
            configurationNodes: [
                {
                    id: {
                        organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                        configurationNodeId:
                            'c4a0818c-4483-4623-b1d6-c8d7a81531b2',
                    },
                    order: 1,
                    quantity: 0,
                    type: 'Text',
                    product: {
                        id: {
                            organizationId:
                                '478242c4-3cbe-48af-9180-1e330e0b83d3',
                            productId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        },
                        minQuantity: 0,
                        maxQuantity: 1,
                        isPartnerProduct: false,
                        readOnly: false,
                        liability: 0.0,
                        name: 'Customers Own Insurance',
                        code: 'CUSTINS',
                        description: '<p></p>',
                        popupInformation: '<p></p>',
                        price: {
                            unitOfMeasure: 'Fixed',
                            amount: 0.0,
                            rateValue: 0.0,
                            currency: 'AUD',
                        },
                        uniqueProductId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        prepaid: false,
                        included: true,
                        requiresQuantity: false,
                    },
                    text: '<p style="font-size: 1.6rem; line-height: 2.4rem;"><b>I have</b> insurance that will cover this rental</p>',
                },
                {
                    id: {
                        organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                        configurationNodeId:
                            '74f3779d-d076-4391-9d82-21dde037d1e3',
                    },
                    quantity: 1,
                    order: 2,
                    type: 'Text',
                    text: '<p style="font-size: 1.6rem; line-height: 2.4rem;"><b>I don\'t have</b> insurance to cover this rental</p>',
                },
            ],
        },
        {
            id: {
                organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                configurationNodeGroupId:
                    '997a8b7f-29f3-4e05-a3b3-94a808437d76',
            },
            code: '3RDPARTY_INS',
            title: '<div><h2 style="color: #1A1A1A; font-weight: 600;">Third party liability insurance</h2><div>',
            description:
                '<p>If you don’t have your own insurance you must purchase one of the following third party liabilty insurance products either online or when you pick up your vehicle.<p>',
            order: 2,
            required: true,
            multiSelect: false,
            display: 'Vertically',
            configurationNodes: [
                {
                    id: {
                        organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                        configurationNodeId:
                            '60f44dae-d3a4-49f0-95c9-e0028e7c7005',
                    },
                    startDate: '2024-03-01T00:00:00+00:00',
                    order: 1,
                    quantity: 0,
                    product: {
                        id: {
                            organizationId:
                                '478242c4-3cbe-48af-9180-1e330e0b83d3',
                            productId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        },
                        minQuantity: 0,
                        maxQuantity: 1,
                        isPartnerProduct: false,
                        readOnly: false,
                        liability: 0.0,
                        name: 'Rental Liability Insurances',
                        code: 'RLI-INS',
                        description: '<p>RLI-INS description</p>',
                        popupInformation:
                            '<p>Pop-up information for RLI-INS</p>',
                        price: {
                            unitOfMeasure: 'Day',
                            amount: 200.0,
                            originalAmount: 250.0,
                            rateValue: 40.0,
                            originalRateValue: 50.0,
                            currency: 'USD',
                            adjustments: [
                                {
                                    code: 'HOLIDAY-DISCOUNT',
                                    name: 'Holiday Discount',
                                    description: 'Discount for holidays',
                                    type: '',
                                    discount: {
                                        value: 20.0,
                                        unit: 'Percentage',
                                    },
                                    amountDueAtSupplier: 100.0,
                                },
                            ],
                        },
                        uniqueProductId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        prepaid: false,
                        included: true,
                        requiresQuantity: false,
                    },
                },
                {
                    id: {
                        organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                        configurationNodeId:
                            '35653357-37d7-4b72-95be-4fdd8787c0db',
                    },
                    order: 2,
                    quantity: 0,
                    product: {
                        id: {
                            organizationId:
                                '478242c4-3cbe-48af-9180-1e330e0b83d3',
                            productId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        },
                        minQuantity: 0,
                        maxQuantity: 1,
                        isPartnerProduct: false,
                        readOnly: false,
                        liability: 0.0,
                        name: 'Enhanced Liability Insurance',
                        code: 'ELI',
                        description: '<p>ELI description</p>',
                        popupInformation: '<p>Pop-up information for ELI</p>',
                        price: {
                            unitOfMeasure: 'Day',
                            amount: 200.0,
                            originalAmount: 250.0,
                            rateValue: 40.0,
                            originalRateValue: 50.0,
                            currency: 'USD',
                            adjustments: [
                                {
                                    code: 'HOLIDAY-DISCOUNT',
                                    name: 'Holiday Discount',
                                    description: 'Discount for holidays',
                                    type: '',
                                    discount: {
                                        value: 20.0,
                                        unit: 'Percentage',
                                    },
                                    amountDueAtSupplier: 100.0,
                                },
                            ],
                        },
                        uniqueProductId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        prepaid: false,
                        included: true,
                        requiresQuantity: false,
                    },
                },
            ],
        },
        {
            id: {
                organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                configurationNodeGroupId:
                    'a06031c6-7498-4337-9619-c1d5db9b4f52',
            },
            code: '3RDPARTY_UP_INS',
            order: 3,
            required: true,
            multiSelect: false,
            display: 'Vertically',
            parent: {
                id: {
                    configurationNodeGroupId:
                        '997a8b7f-29f3-4e05-a3b3-94a808437d76',
                    organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                },
            },
            configurationNodes: [
                {
                    id: {
                        organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                        configurationNodeId:
                            '788a3628-cf71-4bb6-a6d9-636dc7826c5d',
                    },
                    order: 1,
                    quantity: 0,
                    product: {
                        id: {
                            organizationId:
                                '478242c4-3cbe-48af-9180-1e330e0b83d3',
                            productId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        },
                        minQuantity: 0,
                        maxQuantity: 1,
                        isPartnerProduct: false,
                        readOnly: false,
                        liability: 0.0,
                        name: 'Supplementary Liability Insurance',
                        code: 'SLI-INS',
                        description: '<p>SLI-INS description</p>',
                        popupInformation:
                            '<p>Pop-up information for SLI-INS</p>',
                        price: {
                            unitOfMeasure: 'Day',
                            amount: 200.0,
                            rateValue: 40.0,
                            currency: 'USD',
                        },
                        uniqueProductId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        prepaid: false,
                        included: true,
                        requiresQuantity: false,
                    },
                },
            ],
        },
        {
            id: {
                organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                configurationNodeGroupId:
                    '9a57c846-a68e-4697-a4fe-3d378e16167d',
            },
            name: 'INC_COV',
            title: '<div><h2 style="color: #1A1A1A; font-weight: 600;">Vehicle Incident Cover</h2></div>',
            description:
                '<p>Reduce your financial responsibility for vehicle damage by purchasing one of the following products.<p>',
            order: 4,
            required: true,
            multiSelect: false,
            display: 'Vertically',
            configurationNodes: [
                {
                    id: {
                        organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                        configurationNodeId:
                            'ca29c53a-5c6e-4f12-a40b-f7d1ab9816cb',
                    },
                    order: 1,
                    quantity: 0,
                    product: {
                        id: {
                            organizationId:
                                '478242c4-3cbe-48af-9180-1e330e0b83d3',
                            productId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        },
                        minQuantity: 0,
                        maxQuantity: 1,
                        isPartnerProduct: false,
                        readOnly: false,
                        liability: 0.0,
                        name: 'Vehicle Incident Protection',
                        code: 'VIP',
                        description: '<p>VIP description</p>',
                        popupInformation: '<p>Pop-up information for VIP</p>',
                        price: {
                            unitOfMeasure: 'Day',
                            amount: 200.0,
                            rateValue: 40.0,
                            currency: 'USD',
                        },
                        uniqueProductId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        prepaid: false,
                        included: true,
                        requiresQuantity: false,
                    },
                },
                {
                    id: {
                        organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                        configurationNodeId:
                            'f535c4cb-d194-4f0d-8bb9-929231211775',
                    },
                    order: 2,
                    quantity: 0,
                    product: {
                        id: {
                            organizationId:
                                '478242c4-3cbe-48af-9180-1e330e0b83d3',
                            productId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        },
                        minQuantity: 0,
                        maxQuantity: 1,
                        isPartnerProduct: false,
                        readOnly: false,
                        liability: 0.0,
                        name: 'Vehicle Incident Protection Plus',
                        code: 'VIPPLUSPKG',
                        description: '<p>VIPPLUSPKG description</p>',
                        popupInformation:
                            '<p>Pop-up information for VIPPLUSPKG</p>',
                        price: {
                            unitOfMeasure: 'Day',
                            amount: 200.0,
                            rateValue: 40.0,
                            currency: 'USD',
                        },
                        uniqueProductId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        prepaid: false,
                        included: true,
                        requiresQuantity: false,
                    },
                },
            ],
        },
        {
            id: {
                organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                configurationNodeGroupId:
                    '1adaa6aa-1829-4eaf-8dc0-49544d473d38',
            },
            code: 'INC_UP_COV',
            order: 5,
            required: true,
            multiSelect: false,
            display: 'Vertically',
            parent: {
                id: {
                    configurationNodeGroupId:
                        '9a57c846-a68e-4697-a4fe-3d378e16167d',
                    organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                },
            },
            configurationNodes: [
                {
                    id: {
                        organizationId: '478242c4-3cbe-48af-9180-1e330e0b83d3',
                        configurationNodeId:
                            '1aea0539-d776-4ddb-a17e-2b434a24fbde',
                    },
                    order: 1,
                    quantity: 0,
                    product: {
                        id: {
                            organizationId:
                                '478242c4-3cbe-48af-9180-1e330e0b83d3',
                            productId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        },
                        minQuantity: 0,
                        maxQuantity: 1,
                        isPartnerProduct: false,
                        readOnly: false,
                        liability: 0.0,
                        name: 'VIP Plus',
                        code: 'VIPPLUS',
                        description: '<p>VIP Plus description</p>',
                        popupInformation:
                            '<p>Pop-up information for VIP Plus Upgrade</p>',
                        price: {
                            unitOfMeasure: 'Day',
                            amount: 200.0,
                            rateValue: 40.0,
                            currency: 'USD',
                        },
                        uniqueProductId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                        prepaid: false,
                        included: true,
                        requiresQuantity: false,
                    },
                },
            ],
        },
    ] as ConfigurationNodeGroup[],
} as ProductsResponseV2;
