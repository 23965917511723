import { FC } from 'react';
import { PhoneInput } from 'cosmos-components';
import styled from 'styled-components';
import { ValidationError } from 'src/components';
import { Controller } from 'react-hook-form';

import { useConfiguration } from 'src/hooks/useConfiguration';

import { StyledFormField } from '../index';

export interface PhoneInputProps {
    className?: string;
    name: string;
    control: any;
    defaultValue: any;
    label: string;
    placeholder: string;
    onChange?: (value: any) => void;
    disabled?: boolean;
    isRequired?: boolean;
    autoComplete?: 'off' | 'on';
    errorMessage?: string | null;
    testId?: string;
    inputRestProps?: any;
}

const CustomPhoneInput: FC<PhoneInputProps> = ({
    className,
    name,
    control,
    defaultValue,
    label,
    placeholder,
    onChange,
    isRequired,
    autoComplete,
    inputRestProps,
    testId,
}) => {
    const configuration = useConfiguration();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error, invalid } }) => (
                <Wrapper className={className} data-test-id={testId}>
                    <StyledFormField
                        isRequired={isRequired}
                        label={label}
                        name={name}
                    >
                        <PhoneInput
                            testId={testId}
                            value={{
                                countryCode: field.value?.countryCode,
                                phoneNumber:
                                    field.value?.numberWithoutCountryCode,
                            }}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            name={name}
                            onChange={
                                onChange
                                    ? (value) => onChange(value)
                                    : field.onChange
                            }
                            isError={invalid}
                            suggestedCountryCodes={
                                configuration.functionalities.customerDetails
                                    .suggestedCountryCodes
                            }
                            {...inputRestProps}
                        />
                    </StyledFormField>
                    <ValidationError>
                        {(error as any)?.numberWithoutCountryCode?.message}
                    </ValidationError>
                </Wrapper>
            )}
        />
    );
};

export const Wrapper = styled.div`
    flex-basis: '100%';
    flex-grow: 1;
`;

const defaultProps: Partial<PhoneInputProps> = {
    isRequired: true,
    autoComplete: 'off',
    inputRestProps: {},
};
CustomPhoneInput.defaultProps = defaultProps;
export { CustomPhoneInput };
