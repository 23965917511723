import { rest } from 'msw';

import { getApiBaseUrl } from 'src/utils/configStore';

import {
    timeSlotsAvailabilitiesApiRoute,
    associatedTimeSlotsAvailabilitiesRoute,
} from '../hooks/timeslots.api.hooks';

import { MOCK_RES } from './timeslots.api.mock.response';

const baseURL = getApiBaseUrl();
const delayDuration = 1000;

const timeSlotsApiMocks = [
    rest.get(
        `${baseURL}${timeSlotsAvailabilitiesApiRoute}`,
        async (req, res, ctx) => {
            return res(
                ctx.delay(delayDuration),
                ctx.status(200),
                ctx.json(MOCK_RES.AvailableTimeslots),
            );
        },
    ),
    rest.get(
        `${baseURL}${associatedTimeSlotsAvailabilitiesRoute}`,
        async (req, res, ctx) => {
            return res(
                ctx.delay(delayDuration),
                ctx.status(200),
                ctx.json(MOCK_RES.AssociatedTimeSlots),
            );
        },
    ),
    rest.post(
        `${baseURL}/${associatedTimeSlotsAvailabilitiesRoute}`,
        async (req, res, ctx) => {
            return res(ctx.delay(delayDuration), ctx.status(200));
        },
    ),
];

export default timeSlotsApiMocks;
