import { defaultTheme } from 'cosmos-components';
import { css } from 'styled-components';

export const colors = {
    ...defaultTheme.colors,
    orange: '#F58025',
    red: '#e52823',
    black: '#333333',
    grey: '#D0D0D0',
    lightBlack: '#231F20',
    primaryGrey: 'rgb(240, 240, 240)',
    secondaryRed: '#ee3524',
    blue: '#006171',
    secondaryGrey: '#0000000d',
    tertiaryGrey: '#f0f0f0',
    secondaryBlack: '#231f20',
    quaternaryGrey: '#dddddd',
    yellow: '#FFD600',
    secondaryOrange: '#CD2E0A',
    lightOrange: '#FFE8D6',
    notificationError: '#FFEBEB',
    notificationSuccess: '#D4F5DC',
    notificationErrorBorder: '#E52823',
    notificationSuccessBorder: '#21BA45',
    notificationErrorIcon: '#CD2E0A',
    notificationSuccessIcon: '#21BA45',
    transparent: '#fff0',
};

export const size = {
    ...defaultTheme.size,
    medium: {
        width: '2rem',
        height: '2rem',
    },
    medium1: {
        width: '3rem',
        height: '2rem',
    },
};

export const fonts = {
    ...defaultTheme.fonts,
    montserrat: 'Montserrat',
};

export const mediaQueryBreakPoints = {
    screenXSMin: '320px',
    screenXSMax: '480px',
    screenSMin: '481px',
    screenSMax: '767px',
    screenMDMin: '768px',
    screenMDMax: '1024px',
    screenLMin: '1025px',
    screenLMax: '1280px',
    screenXLMin: '1281px',
};

export const sciTheme = {
    ...defaultTheme,
    name: 'SCI Theme',
    shape: {
        input: {
            borderRadius: '6px',
            padding: '0.8rem 1.6rem',
        },
        button: {
            borderRadius: '20px',
            padding: '10px',
        },
        select: {
            width: '100%',
            height: '3.5rem',
            borderRadius: '6px',
            padding: '0.8px 1.6rem',
        },
    },
    overrides: {
        Button: {
            // Override component styling
            fontSize: '1.6rem',
            paddingTop: '0.8rem',
            paddingBottom: '0.8rem',
            height: '3.5rem',
            fontFamily: `${fonts.montserrat}`,
            fontWeight: `bold`,
            textTransform: 'capitalize',
            '&:focus': {
                boxShadow: 'unset',
            },
            PrimaryButton: {
                backgroundColor: colors.secondaryOrange,
                '&:hover, &:focus, &:active': {
                    backgroundColor: colors.secondaryOrange,
                },
                height: '3.5rem',
            },
        },
        SecondaryButton: {
            // Override component styling
            backgroundColor: colors.secondaryOrange,
        },
        input: {
            height: '3.5rem',
            backgroundColor: `${colors.white}`,
            color: '#333333',
            fontSize: '1.4rem',
            fontFamily: `${fonts.montserrat}`,
            border: `solid 0.1rem ${colors.grey}`,
        },
        PlainInput: {
            color: '#333333',
            fontSize: '1.4rem',
            fontFamily: `${fonts.montserrat}`,
        },
        Label: {
            textTransform: 'inherit',
            fontWeight: 700,
            height: '1.5rem',
            fontSize: '1.4rem',
            marginBottom: '0.5rem',
        },
        NotificationBanner: {
            height: 'auto',
            padding: '0.8rem',
            NotificationIcon: {
                alignSelf: 'flex-start',
            },
        },
        Select: css`
            width: 100%;
            font-size: 1.4rem;
            select {
                height: 3.5rem;
                width: 100%;
                outline: none;
            }
            div:last-child {
                top: 1.25rem;
            }
        `,
        PhoneInput: {
            height: '3.5rem',
            minHeight: '3.5rem',
            borderRadius: '6px',
        },
        Checkbox: {},
        Link: {
            textDecoration: 'underline',
            color: `${colors.white}`,
        },
    },
    colors,
    size,
    mediaQueryBreakPoints,
};
