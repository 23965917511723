import { defineMessages } from 'react-intl';

const pageItems = defineMessages({
    title: {
        id: 'timeslots.pageItems.title',
        defaultMessage: 'Pick Up/Drop Off Time Slots',
    },
    formTitle: {
        id: 'timeslots.pageItems.formTitle',
        defaultMessage: 'Time Slots Selection',
    },
});

const buttons = defineMessages({
    continue: {
        id: 'timeslots.buttons.continue',
        defaultMessage: 'Save & Continue',
    },
});

const form = defineMessages({
    pickUp: {
        id: 'timeslots.form.pickUp',
        defaultMessage: 'Pick Up Time',
    },
    dropOff: {
        id: 'timeslots.form.dropOff',
        defaultMessage: 'Drop Off Time',
    },
    placeholder: {
        id: 'timeslots.form.placeholder',
        defaultMessage: 'Select...',
    },
});

const formError = defineMessages({
    pickUp: {
        id: 'timeslots.formError.pickUp',
        defaultMessage: 'Pick Up Time is required',
    },
    dropOff: {
        id: 'timeslots.formError.dropOff',
        defaultMessage: 'Drop Off Time is required',
    },
    header: {
        id: 'timeslots.formError.header',
        defaultMessage: 'Sorry!',
    },
    body: {
        id: 'timeslots.formError.body',
        defaultMessage:
            'The selected time slot is no longer available. Please select a different option.',
    },
});

const label = defineMessages({
    free: {
        id: 'timeslots.label.free',
        defaultMessage: 'Free',
    },
    full: {
        id: 'timeslots.label.full',
        defaultMessage: 'Full',
    },
});

const timeSlotsMessages = {
    pageItems,
    buttons,
    form,
    formError,
    label,
};

export default timeSlotsMessages;
