import { set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { Input } from 'cosmos-components';
import { useIntl } from 'react-intl';
import { placesAutoCompleteMsgs } from './messages';

let autoComplete: any;

const initialise = (
    updateQuery: any,
    autoCompleteRef: any,
    onAddressFound: any,
) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
            types: ['address'],
        },
    );
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.addListener('place_changed', () =>
        handlePlaceSelect(updateQuery, onAddressFound),
    );
};

const handlePlaceSelect = (updateQuery: any, onAddressFound: any) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    const address = parseAddress(addressObject.address_components);
    address.formattedAddress = query;
    onAddressFound(address);
};

const addressDefinition: any = {
    number: {
        type: ['street_number'],
        field: 'long_name',
    },
    street: {
        type: ['route'],
        field: 'long_name',
    },
    line2: {
        type: ['sublocality_level_1', 'sublocality', 'political'],
        field: 'long_name',
    },
    city: {
        type: ['postal_town', 'locality', 'administrative_area_level_2'],
        field: 'long_name',
    },
    state: { type: 'administrative_area_level_1', field: 'long_name' },
    stateCode: { type: 'administrative_area_level_1', field: 'short_name' },
    countryCode: { type: 'country', field: 'short_name' },
    country: { type: 'country', field: 'long_name' },
    postCode: { type: 'postal_code', field: 'long_name' },
};

const parseAddress = (addressComponent: any) => {
    let stateValue = '';
    const resultAddress = {} as Address;

    Object.keys(addressDefinition).forEach((key) => {
        const value = addressComponent.find((o: { types: string[] }) =>
            o.types.some((e) => addressDefinition[key].type.includes(e)),
        );

        let inputValue = value ? value[addressDefinition[key].field] : null;
        if (key === 'state') {
            stateValue = inputValue;
        }
        if (key === 'stateCode' && stateValue === inputValue) {
            inputValue = null;
        }
        set(resultAddress, key, inputValue);
    });
    return resultAddress;
};

export type Address = {
    number: string;
    street: string;
    line2: string;
    city: string;
    postCode: string;
    country: string;
    countryCode: string;
    state: string;
    stateCode: string;
    formattedAddress: string;
};

export type SearchLocationInputProps = {
    ['data-test-id']?: string;
    label?: string;
    className?: string;
    placeholder?: string;
    onAddressFound: (address: Address) => void;
    onBlur?: () => void;
};

export const SearchLocationInput = ({
    'data-test-id': testId,
    onAddressFound,
    label,
    placeholder,
    onBlur,
}: SearchLocationInputProps) => {
    const [query, setQuery] = useState('');
    const autoCompleteRef = useRef(null);
    const [focus, setFocus] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        initialise(setQuery, autoCompleteRef, onAddressFound);
    }, []);

    placeholder = placeholder ?? label;
    label = label ?? placeholder;

    const controlTestId = `${testId}-search-location-input`;

    return (
        <div data-test-id={`${controlTestId}-container`}>
            <div
                onBlur={() => setFocus(false)}
                onFocus={() => {
                    setQuery('');
                    setFocus(true);
                }}
            >
                <Input
                    value={query}
                    onInputChange={(value) => setQuery(value)}
                    testId="Input2"
                    data-test-id={`${controlTestId}-input`}
                    type="text"
                    autoComplete="off"
                    ref={autoCompleteRef}
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={onBlur}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }}
                    placeholder={intl.formatMessage(
                        placesAutoCompleteMsgs.searchPlaces,
                    )}
                />
            </div>
        </div>
    );
};
