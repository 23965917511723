import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { AvailabilityType, TimeSlot } from '../timeslots.types';
import {
    getTimeSlotsAvailabilities,
    useGetTimeSlotsAvailabilitiesKey,
} from './timeslots.api.hooks';

export interface UseTimeSlotsAssociation {
    isLoading: boolean;
    data: TimeSlot[];
}

const useTimeSlotsAssociation = (
    availabilityType: AvailabilityType,
): UseTimeSlotsAssociation => {
    const { isLoading, isFetching, isRefetching, data } = useQuery({
        queryKey: [useGetTimeSlotsAvailabilitiesKey, availabilityType],
        queryFn: async () => await getTimeSlotsAvailabilities(availabilityType),
    });

    return {
        isLoading: isLoading || isFetching || isRefetching,
        data: data ?? [],
    };
};

export interface UseTimeSlotsAssociations {
    isLoading: boolean;
    pickUpAvailabilities: TimeSlot[];
    dropOffAvailabilities: TimeSlot[];
}

export const useTimeSlotsAssociations = (): UseTimeSlotsAssociations => {
    const {
        data: pickUpAvailabilities,
        isLoading: pickUpAvailabilitiesIsLoading,
    } = useTimeSlotsAssociation(AvailabilityType.pickUp);
    const {
        data: dropOffAvailabilities,
        isLoading: dropOffAvailabilitiesIsLoading,
    } = useTimeSlotsAssociation(AvailabilityType.dropOff);

    const [isLoading, setIsLoading] = useState(
        pickUpAvailabilitiesIsLoading || dropOffAvailabilitiesIsLoading,
    );
    useEffect(() => {
        setIsLoading(
            pickUpAvailabilitiesIsLoading || dropOffAvailabilitiesIsLoading,
        );
    }, [pickUpAvailabilitiesIsLoading, dropOffAvailabilitiesIsLoading]);

    return {
        pickUpAvailabilities,
        dropOffAvailabilities,
        isLoading,
    };
};
